import React from 'react';
import SEO from '../components/seo';

const styles: React.CSSProperties = {
    textAlign: "center",
    color: "white",
    fontSize: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
}

const Unavailable = () => {
    return (
        <div style={styles}>
            <SEO title="No disponible" />
            <h1>
                Este sitio web ha sido suspendido por falta de pago.
            </h1>
        </div>
    );
};

export default Unavailable;